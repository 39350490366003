import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import pxdStamp from "../../../assets/images/pxdStamp.png";
import SignaturePad from "signature_pad";
import { isEmpty } from "../../../lib/utils";
import { Button } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import CreateIcon from "@material-ui/icons/Create";

const useStyles = makeStyles((theme) => ({
  section: {
    marginTop: "30px",
    padding: "20px 0",
    lineHeight: 1.6,
    borderTop: "1px solid #333",
    "& p": {
      marginTop: "10px",
    },
    "& > ul > li": {
      marginTop: "10px",
    },
    "& li": {
      paddingLeft: "15px",
    },
  },
  title: {
    padding: "15px 0",
    fontSize: "20px",
    fontWeight: 700,
    textAlign: "center",
  },
  box: {
    margin: "15px 0",
    padding: "15px",
    border: "1px solid #333",
  },
  bold: {
    fontWeight: 700,
  },
  alignRight: {
    textAlign: "right",
  },
  signPad: {
    marginTop: "20px",
    textAlign: "right",
    "& .signature": {
      display: "inline-block",
      width: "335px",
      height: "200px",
      border: "1px dotted #333",
    },
  },
}));

let canvas;
let signaturePad;

const UserSurvey04 = ({
  mode,
  idx,
  userName,
  projectName,
  date,
  signImg,
  signImgList,
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (mode === "viewer") {
      canvas = document.querySelector(`#signature${idx}`);
      signaturePad = new SignaturePad(canvas);
    }
  }, []);

  const saveSign = () => {
    let data = signaturePad.toDataURL("image/png");

    if (signaturePad.isEmpty()) {
      alert("서명 후 저장해주세요.");
      return false;
    }

    signImgList(3, data);
    alert("저장되었습니다.");
  };

  const clearSign = () => {
    signaturePad.clear();
  };

  const setSignPad = () => {
    return (
      <div className={classes.signPad}>
        <canvas
          id={`signature${idx}`}
          className="signature"
          width="335px"
          height="200px"
        ></canvas>
        <div className={classes.wrapBtn}>
          <Button
            className="clear"
            variant="contained"
            // disabled={apply}
            startIcon={<ClearIcon />}
            onClick={clearSign}
          >
            Clear
          </Button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Button
            className="save"
            variant="contained"
            color="primary"
            // disabled={apply}
            startIcon={<CreateIcon />}
            onClick={saveSign}
          >
            Save
          </Button>
        </div>
      </div>
    );
  };

  const signView = (imgSrc) => {
    if (!isEmpty(imgSrc)) {
      return (
        <div className={classes.signPad}>
          <img src={imgSrc} className="signature" alt="사인 이미지" />
        </div>
      );
    }
  };

  return (
    <div className={`${classes.section} section`}>
      <p className={classes.title}>Confidentiality Agreement</p>
      <p>
        <span className={classes.bold}>{userName}</span> (hereinafter referred
        to as “subject”) and <span className={classes.bold}>pxd inc</span>{" "}
        (hereinafter referred to as “provider”) enter into a confidentiality
        agreement with respect to the{" "}
        <span className={classes.bold}>“{projectName}”</span> as follows.
      </p>

      <ul>
        <li>
          <span className={classes.bold}>Article 1 [Purpose)</span>
          <br />
          The purpose of this contract is to protect the confidential
          information of both parties in explaining and presenting the contents
          related to the <span className={classes.bold}>
            “{projectName}”
          </span>{" "}
          which the "provider" has the right to "subject".
        </li>
        <li>
          <span className={classes.bold}>Article 2 (applied information)</span>
          <br />
          <ol>
            <li>
              ① Confidential information provided by the "provider" to the
              "subject" pursuant to this contract is as follows.
              <ol>
                <li>
                  1. Videos, images, and documents presented by the “provider”
                </li>
                <li>
                  2. Contents such as text, video, and pitch questions presented
                  by the “provider”
                </li>
                <li>
                  3. Any other material requiring security by the “provider”
                </li>
              </ol>
            </li>
            <li>
              ② Confidential information of each party stipulated in the
              preceding paragraph is called as confidential information under
              this contract.
            </li>
          </ol>
        </li>
        <li>
          <span className={classes.bold}>
            Article 3 (restrictions on use and export of confidential
            information)
          </span>
          <br />
          <ol>
            <li>
              ① “Subjects” who have been provided with confidential information
              can use the information only in the range of answers to
              questionnaires and interviews.
            </li>
            <li>
              ② “Subjects” who have been provided with confidential information
              must not store the information separately or take it out outside
              by copying, recording, recording, third-party shooting, or screen
              save.
            </li>
          </ol>
        </li>
        <li>
          <span className={classes.bold}>
            Article 4 (Confidentiality period)
          </span>
          <br />
          Each party shall protect the confidential information in accordance
          with the provisions of this agreement for three years from the date
          the other party provides the confidential information.
        </li>
        <li>
          <span className={classes.bold}>
            Article 5 (degree of duty of care)
          </span>
          <br />A party to whom confidential information is provided shall
          protect the confidential information with a duty of care equal to the
          duty of care given to protect their confidential information.
        </li>
        <li>
          <span className={classes.bold}>
            Article 6 (Exception of confidential information)
          </span>
          <br />
          Information falling under each of the following subparagraphs shall
          not be treated as confidential information.
          <ol>
            <li>
              1. Information held by the party that was provided prior to the
              provision of confidential information
            </li>
            <li>
              2. Information that is notified without negligence of the party to
              whom confidential information was provided
            </li>
            <li>
              3. Information legally provided by the party who received
              confidential information from a third party
            </li>
            <li>
              4. Information that the party who provided confidential
              information allowed to leak in advance
            </li>
          </ol>
        </li>
        <li>
          <span className={classes.bold}>Article 7 (guarantee)</span>
          <br />
          The party providing Confidential Information warrants that it has a
          legitimate right to provide Confidential Information.
        </li>
        <li>
          <span className={classes.bold}>
            Article 8 (Intellectual Ownership)
          </span>
          <br />
          Intellectual property rights owned by each party prior to the
          conclusion of this Agreement shall remain intact to the original
          party, and this Agreement shall not be construed as granting or
          granting intellectual property rights to the other party.
        </li>
        <li>
          <span className={classes.bold}>Article 9 (return of data)</span>
          <br />
          In the case of a request for return of all data, equipment, documents,
          and other information provided by each party to the other party, the
          relevant data and information shall be returned immediately along with
          a copy thereof.
        </li>
        <li>
          <span className={classes.bold}>
            Article 10 (Compensation for Damage)
          </span>
          <br />
          If the person in charge and the employee of the “subject” or “subject”
          disclose or disclose confidential information to a third party and
          cause damage to the “provider”, all damages shall be compensated.
        </li>
        <li>
          <span className={classes.bold}>
            Article 11 (Imposition of other obligations)
          </span>
          <br />
          This Agreement does not impose any obligation on the parties, such as
          sale, purchase, license grant, assignment or disposal of any
          particular technology, service or product.
        </li>
        <li>
          <span className={classes.bold}>Article 12 (transfer)</span>
          <br />
          The rights and obligations of this Agreement shall not be transferred
          to a third party without the prior written consent of the other party.
        </li>
        <li>
          <span className={classes.bold}>
            Article 13 (modification of contract)
          </span>
          <br />
          Any modification or addition of provisions to this Agreement shall be
          made by the legitimate representatives of both parties.
        </li>
        <li>
          <span className={classes.bold}>Article 14 (Competent Court)</span>
          <br />
          All disputes, etc. related to this contract shall be governed by the
          court in the location of the head office of the “subject”.
        </li>
      </ul>

      <p className={classes.alignRight}>{date}</p>

      <p className={classes.alignRight}>“Subject“</p>
      <p className={classes.alignRight}>
        <span className={classes.bold}>{userName}</span>(Handwriting required)
        (Signature)
      </p>
      {mode === "view" && signView(signImg)}
      {mode === "print" && signView(signImg)}
      {mode === "viewer" && setSignPad()}

      <p className={classes.alignRight}>“Provider”</p>
      <p className={classes.alignRight}>
        <span className={classes.bold}>㈜pxd</span> (Signature required)
        <br />
        <img src={pxdStamp} alt="피엑스디 직인" />
      </p>
    </div>
  );
};

export default UserSurvey04;
