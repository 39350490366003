import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SignaturePad from "signature_pad";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { isEmpty } from "../../../lib/utils";
import { Button } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles((theme) => ({
  section: {
    marginTop: "30px",
    padding: "20px 0",
    lineHeight: 1.6,
    borderTop: "1px solid #333",
    "& p": {
      marginTop: "10px",
    },
    "& li li": {
      paddingLeft: "15px",
    },
  },
  title: {
    padding: "15px 0",
    fontSize: "20px",
    fontWeight: 700,
    textAlign: "center",
  },
  box: {
    margin: "15px 0",
    padding: "15px",
    border: "1px solid #333",
    "& > ul > li": {
      marginTop: "10px",
    },
  },
  bold: {
    fontWeight: 700,
    wordBreak: "break-all",
  },
  alignRight: {
    textAlign: "right",
  },
  signPad: {
    marginTop: "20px",
    textAlign: "right",
    "& .signature": {
      display: "inline-block",
      width: "335px",
      height: "200px",
      border: "1px dotted #333",
    },
  },
}));

let canvas;
let signaturePad;

const UserSurvey02 = ({
  mode,
  idx,
  projectName,
  clientName,
  date,
  userName,
  signImg,
  signImgList,
}) => {
  const classes = useStyles();
  const [agree, setAgree] = useState({
    agree01: !isEmpty(signImg) ? "yes" : "",
    agree02: !isEmpty(signImg) ? "yes" : "",
  });

  useEffect(() => {
    if (mode === "viewer") {
      canvas = document.querySelector(`#signature${idx}`);
      signaturePad = new SignaturePad(canvas);
    }
  }, []);

  const saveSign = (e) => {
    if (agree.agree01 === "yes" && agree.agree02 === "yes") {
      let data = signaturePad.toDataURL("image/png");

      if (signaturePad.isEmpty()) {
        alert("서명 후 저장해주세요.");
        return false;
      }

      signImgList(1, data);
      alert("저장되었습니다.");

      let thisEl = e.target.closest(".section");
      let nextEl = thisEl.nextElementSibling;
      let nextElFlag =
        nextEl && thisEl.nextElementSibling.classList.contains("section");

      if (nextElFlag) {
        // const y = nextEl.getBoundingClientRect().top + window.scrollY;

        let ua = window.navigator.userAgent;
        let msie = ua.indexOf("MSIE ");

        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
          // If Internet Explorer
          window.scrollTo(0, nextEl.offsetTop);
        } else {
          // If another browser
          // not support ios safari -_-;;
          window.scroll({
            top: nextEl.offsetTop,
            behavior: "smooth",
          });
        }
      }
    } else {
      alert(
        "개인정보 수집·이용 동의 및 제 3자에게 제공하는 것에 동의가 필요합니다."
      );
    }
  };

  const clearSign = () => {
    signaturePad.clear();
  };

  const setSignPad = () => {
    return (
      <div className={classes.signPad}>
        <canvas
          id={`signature${idx}`}
          className="signature"
          width="335px"
          height="200px"
        ></canvas>
        <div className={classes.wrapBtn}>
          <Button
            className="clear"
            variant="contained"
            // disabled={apply}
            startIcon={<ClearIcon />}
            onClick={clearSign}
          >
            Clear
          </Button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Button
            className="save"
            variant="contained"
            color="primary"
            // disabled={apply}
            startIcon={<CreateIcon />}
            onClick={(e) => saveSign(e)}
          >
            Save
          </Button>
        </div>
      </div>
    );
  };

  const signView = (imgSrc) => {
    if (!isEmpty(imgSrc)) {
      return (
        <div className={classes.signPad}>
          <img src={imgSrc} className="signature" alt="사인 이미지" />
        </div>
      );
    }
  };

  const handleChange = (e) => {
    setAgree({
      ...agree,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className={`${classes.section} section`}>
      <p className={classes.title}>
        Consent to collection and provision of personal information for user
        research/usability testing and provision to third parties
      </p>
      <p>
        pxd, inc. complies with the personal information protection regulations
        in relevant laws, collects/uses the personal information of research
        respondents, and provides it to a third party.
      </p>
      <p>
        pxd, inc. intends to collect/use personal information and provide it to
        third parties as follows for the “
        <span className={classes.bold}>{projectName}</span>” research, so please
        read the details carefully and decide whether to consent.
      </p>

      <div className={classes.box}>
        <ul>
          <li>
            1. Personal information collected/used
            <ol>
              <li>
                ① Respondent's name, age, gender, place of residence, contact
                information, and other details/responses provided as part of
                this research etc.
              </li>
              <li>
                ② Respondent's resident registration number, account
                information, and contact information
              </li>
            </ol>
          </li>
          <li>
            2. Purpose of collection/use
            <ol>
              <li>
                Item ①: Used as research and research data for the“
                <span className={classes.bold}>{projectName}</span>”
              </li>
              <li>
                Item ②: Tax/Accounting Purpose of Research Participant’s
                Incentive
              </li>
            </ol>
          </li>
          <li>
            3. Personal information retention period
            <ol>
              <li>
                Item ①: 3 years after the end of the “
                <span className={classes.bold}>{projectName}</span>”
              </li>
              <li>Item ②: 5 years</li>
            </ol>
          </li>
          <li>
            4. Details of personal information provided to a third party in
            relation to item ①
            <ul>
              <li>
                Institution to be provided:{" "}
                <span className={classes.bold}>{clientName}</span>
              </li>
              <li>
                Purpose of provision: Research and research data of the “
                <span className={classes.bold}>{projectName}</span>
              </li>
              <li>Items to be provided: Same as item ① in Paragraph 1 </li>
              <li>Retention period: Same as item ① in Paragraph 3</li>
            </ul>
          </li>
          <li>
            Details of personal information provided to a third party in
            relation to item ②
            <ul>
              <li>Provided institution: SeAH Accounting Firm</li>
              <li>
                Purpose of provision: Accounting/tax processing related to
                respondents' compensation
              </li>
              <li>Items to be provided: Same as item ② in Paragraph 1</li>
              <li>Retention period: Same as item ② in Paragraph 3</li>
            </ul>
          </li>
          <li>
            6. Information on the right to refuse consent
            <ul>
              <li>
                Respondents have the right to refuse to consent to the provision
                of personal information as above. However, if you refuse to
                consent, you cannot participate in the research, and the
                research will end immediately.
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <p>
        Do you agree to the collection and use of personal information as above?
      </p>
      <RadioGroup
        aria-label="Do you agree to the collection and use of personal information as above?"
        name="agree01"
        value={agree.agree01}
        onChange={handleChange}
      >
        <FormControlLabel
          value="yes"
          control={<Radio />}
          label="Yes, I agree"
          disabled={!isEmpty(signImg) || mode !== "viewer"}
        />
        <FormControlLabel
          value="no"
          control={<Radio />}
          label="No, I do not agree"
          disabled={!isEmpty(signImg) || mode !== "viewer"}
        />
      </RadioGroup>

      <p>
        Do you agree to the provision of personal information to third parties
        as above?
      </p>
      <RadioGroup
        aria-label="개인정보를 제 3자에게 제공하는 것에 동의 하십니까?"
        name="agree02"
        value={agree.agree02}
        onChange={handleChange}
      >
        <FormControlLabel
          value="yes"
          control={<Radio />}
          label="Yes, I agree"
          disabled={!isEmpty(signImg) || mode !== "viewer"}
        />
        <FormControlLabel
          value="no"
          control={<Radio />}
          label="No, I do not agree"
          disabled={!isEmpty(signImg) || mode !== "viewer"}
        />
      </RadioGroup>

      <p className={classes.alignRight}>{date}</p>
      <p className={classes.alignRight}>
        Full Name: <span className={classes.bold}>{userName}</span>
        (Signature Required)
      </p>

      {mode === "view" && signView(signImg)}
      {mode === "print" && signView(signImg)}
      {mode === "viewer" && setSignPad()}
    </div>
  );
};

export default UserSurvey02;
